import {
  COASegment,
  type FilterOperation,
  GetDriverMappingsV2Response,
  UpdateActionEnum,
} from '@amzn/allocations-service';
import type { DriverMappingRecord } from '@amzn/allocations-service';
import { DriverMapping } from 'src/common/types/DriverMapping';
import { TreeNode } from 'src/common/components/tree-select/TreeSelect';
import { AllocationServiceApi } from 'src/api/AllocationServiceApi';
import { downloadFileWithPresignedUrl } from 'src/api/s3Api';
import { NextPageResponse } from 'src/common/components/server-side-table/ServerSideTable';
import { toDriverMappingRecordsByActionMap } from 'src/common/utils/driverMapping';

const getTreeNodeValue = (value: string) => value.split('|').pop() ?? '';

export const getDriverMapping = (
  driverMapping: DriverMapping,
  dimension: COASegment,
  value: string,
) => {
  switch (dimension) {
    case COASegment.ACCOUNT: {
      driverMapping['account'] = getTreeNodeValue(value);
      driverMapping['accountExclusions'] = [];
      break;
    }
    case COASegment.CHANNEL: {
      driverMapping['channelCode'] = getTreeNodeValue(value);
      driverMapping['channelCodeExclusions'] = [];
      break;
    }
    case COASegment.COMPANY: {
      driverMapping['companyCode'] = getTreeNodeValue(value);
      driverMapping['companyCodeExclusions'] = [];
      break;
    }
    case COASegment.COSTCENTER: {
      driverMapping['costCenter'] = getTreeNodeValue(value);
      driverMapping['costCenterExclusions'] = [];
      break;
    }
    case COASegment.LOCATION: {
      driverMapping['locationCode'] = getTreeNodeValue(value);
      driverMapping['locationCodeExclusions'] = [];
      break;
    }
    case COASegment.PRODUCT: {
      driverMapping['productCode'] = getTreeNodeValue(value);
      driverMapping['productCodeExclusions'] = [];
      break;
    }
    case COASegment.PROJECT: {
      driverMapping['projectCode'] = getTreeNodeValue(value);
      driverMapping['projectCodeExclusions'] = [];
      break;
    }
  }
};

export const getDriverMappingRecord = (
  driverMapping: DriverMappingRecord,
  dimension: COASegment,
  value: string,
) => {
  switch (dimension) {
    case COASegment.ACCOUNT: {
      driverMapping['account'] = getTreeNodeValue(value);
      driverMapping['accountExclusions'] = [];
      break;
    }
    case COASegment.CHANNELTYPE: {
      driverMapping['channelType'] = getTreeNodeValue(value);
      driverMapping['channelTypeExclusions'] = [];
      break;
    }
    case COASegment.COMPANY: {
      driverMapping['companyCode'] = getTreeNodeValue(value);
      driverMapping['companyCodeExclusions'] = [];
      break;
    }
    case COASegment.COSTCENTER: {
      driverMapping['costCenter'] = getTreeNodeValue(value);
      driverMapping['costCenterExclusions'] = [];
      break;
    }
    case COASegment.LOCATION: {
      driverMapping['locationCode'] = getTreeNodeValue(value);
      driverMapping['locationCodeExclusions'] = [];
      break;
    }
    case COASegment.PRODUCT: {
      driverMapping['productCode'] = getTreeNodeValue(value);
      driverMapping['productCodeExclusions'] = [];
      break;
    }
    case COASegment.PROJECT: {
      driverMapping['projectCode'] = getTreeNodeValue(value);
      driverMapping['projectCodeExclusions'] = [];
      break;
    }
  }
};

/**
 * This function will return all possible combinations of input array.
 * Example: getCombinations([1, 2], [3, 4]) => [[1,3],[1,4],[2,3],[2,4]]
 */
export const getCombinations = (...arr: string[][]) =>
  arr.reduce<string[][]>(
    (acc, val) =>
      acc
        .map((el) => val.map((element) => el.concat([element])))
        .reduce((acc, val) => acc.concat(val), []),
    [[]],
  );

/**
 * This function returns a map of child nodes for each node in the tree.
 */
export const getTreeChildNodeMap = (
  treeNode: TreeNode[],
): Record<string, string[]> => {
  const treeChildNodeMap: Record<string, string[]> = {};

  const traverseNode = (node: TreeNode) => {
    if (node.children?.length) {
      treeChildNodeMap[node.key as string] = node.children.map(
        (child) => child.key as string,
      );
      node.children.forEach(traverseNode);
    }
  };

  treeNode.forEach(traverseNode);
  return treeChildNodeMap;
};

export enum DriverMappingAction {
  ADD = 'ADD',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
}

/**
 * This function will return the value of the driver mapping based on the id.
 * @param DriverMappingRecord - DriverMappingRecord object
 * @param id - COASegment enum value
 * @returns - string value of the driver mapping or undefined if not found
 */
export const getDriverMappingRecordValue = (
  driverMapping: DriverMappingRecord,
  id: COASegment,
): string | undefined => {
  const mappingKeys: Partial<Record<COASegment, keyof DriverMappingRecord>> = {
    [COASegment.ACCOUNT]: 'account',
    [COASegment.CHANNELTYPE]: 'channelType',
    [COASegment.COMPANY]: 'companyCode',
    [COASegment.COSTCENTER]: 'costCenter',
    [COASegment.LOCATION]: 'locationCode',
    [COASegment.PRODUCT]: 'productCode',
    [COASegment.PROJECT]: 'projectCode',
  };

  const key = mappingKeys[id];
  return key ? (driverMapping[key] as string) : undefined;
};

const actionToUpdateEnumMapping: Map<DriverMappingAction, UpdateActionEnum> =
  new Map([
    [DriverMappingAction.ADD, UpdateActionEnum.CREATE_OR_UPDATE],
    [DriverMappingAction.EDIT, UpdateActionEnum.CREATE_OR_UPDATE],
    [DriverMappingAction.DELETE, UpdateActionEnum.DELETE],
  ]);

export const downloadDriverMappingHandler = async (
  driverMappingId: string,
  fileName: string,
): Promise<void> => {
  const downloadableUrl =
    await AllocationServiceApi.getDownloadableDriverMappingPath({
      dmId: driverMappingId,
    });

  await downloadFileWithPresignedUrl(downloadableUrl.downloadUrl!, fileName);
};

export const getNextPageDriverMappingsHandler =
  (driverMappingId: string) =>
  async (
    pageSize: number,
    paginationToken?: string,
    filters?: Record<string, FilterOperation>,
  ): Promise<NextPageResponse> => {
    const response: GetDriverMappingsV2Response =
      await AllocationServiceApi.getDriverMappingsV2({
        dmId: driverMappingId,
        filters,
        startKey: paginationToken,
        limit: pageSize,
      });

    return {
      driverMappings: response.driverMappings!,
      paginationToken: response.paginationToken,
    };
  };

export const updateDriverMappingsHandler =
  (driverMappingId: string) =>
  async (
    driverMappingEntries: Map<DriverMappingAction, DriverMappingRecord[]>,
  ) => {
    const driverMappingRecordsToUpdate =
      toDriverMappingRecordsByActionMap(driverMappingEntries);
    //TODO: Implement batched update calls
    const updateDriverMappingRecords = {
      records: Array.from(driverMappingRecordsToUpdate.entries()).flatMap(
        ([action, driverMappingRecords]) =>
          driverMappingRecords.map((record) => ({
            action: actionToUpdateEnumMapping.get(action)!,
            driverMapping: record,
          })),
      ),
      dmId: driverMappingId,
      isTerminalCall: true,
    };
    await AllocationServiceApi.updateDriverMappingEntries(
      updateDriverMappingRecords,
    );
  };
