import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SpaceBetween,
  FormField,
  Input,
  Button,
} from '@cloudscape-design/components';
import { sliceWorkflowColumnDefinitions } from 'src/common/components/driver-mapping-components/config/driver-mapping-table';
import {
  downloadDriverMappingHandler,
  getNextPageDriverMappingsHandler,
  updateDriverMappingsHandler,
} from 'src/common/components/driver-mapping-components/utils/driverMapping';

import { Config } from 'src/common/types/Config';
import { ServerSideTable } from 'src/common/components/server-side-table/ServerSideTable';
import { filteringProperties } from 'src/shadow-pnl-allocations/pages/configure-slice-allocation-methodology-page/utils/slice-driver-mapping-filter-properties';

interface DriverMappingStepProps {
  config: Config;
  onConfigChange(action: { type: string; payload?: Partial<Config> }): void;
  driverMappingId?: string;
  onDriverMappingIdChange(value: string): void;
}

export const DriverMappingStep: FC<DriverMappingStepProps> = (props) => {
  const { t } = useTranslation();
  const colDefs = useMemo(() => sliceWorkflowColumnDefinitions(t), [t]);
  const [driverMappingId, setDriverMappingId] = useState<string | undefined>(
    props.driverMappingId,
  );
  const [isEditing, setIsEditing] = useState<boolean>(!props.driverMappingId);

  const downloadDriverMapping = useCallback(
    () =>
      downloadDriverMappingHandler(
        driverMappingId!,
        `${props.config.region}-${driverMappingId}.json`,
      ),
    [driverMappingId],
  );
  const getNextPageDriverMappingEntries = useCallback(
    getNextPageDriverMappingsHandler(driverMappingId!),
    [driverMappingId],
  );
  const updateDriverMappingEntries = useCallback(
    updateDriverMappingsHandler(driverMappingId!),
    [driverMappingId],
  );

  const handleEditClick = () => {
    setIsEditing(true);
  };

  return (
    <>
      <SpaceBetween direction="vertical" size="l">
        <FormField label={t('driver_mapping_id')} stretch={true}>
          <SpaceBetween direction="horizontal" size="xs">
            <Input
              value={props.driverMappingId || ''}
              onChange={({ detail }) => {
                if (detail.value !== props.driverMappingId) {
                  setDriverMappingId(detail.value);
                  props.onDriverMappingIdChange(detail.value);
                }
              }}
              disabled={!isEditing}
              placeholder={t('enter_driver_mapping_id')}
              data-testid="driver-mapping-id-input"
            />
            {props.driverMappingId && !isEditing && (
              <Button
                iconName="edit"
                variant="icon"
                onClick={handleEditClick}
                ariaLabel={t('edit_driver_mapping_id')}
              />
            )}
          </SpaceBetween>
        </FormField>
        {driverMappingId && (
          <ServerSideTable
            key={driverMappingId}
            driverMappingId={driverMappingId}
            columnDefinitions={colDefs}
            header={t('driver_mappings')}
            filteringProperties={filteringProperties(t)}
            testId="driver-mapping-table"
            handleDownloadCallback={downloadDriverMapping}
            fetchNextPage={getNextPageDriverMappingEntries}
            updateDriverMappingEntries={updateDriverMappingEntries}
            scenario={props.config.scenario!.value}
          />
        )}
      </SpaceBetween>
    </>
  );
};
