import { Wizard } from '@cloudscape-design/components';
import React, {
  lazy,
  startTransition,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Action } from '@amzn/fae-auth-service';
import { TaskType } from '@amzn/taskscheduling-service';
import { useSteps } from './utils/useSteps';
import { Business } from './config';
import { NavigateDetailEvent } from 'src/common/types/Events';
import { i18nStrings } from 'src/common/constants/wizardi18nStrings';
import { reducer } from 'src/common/utils/reducer';
import { Config, defaultConfig } from 'src/common/types/Config';
import { Schedule, defaultSchedule } from 'src/common/types/Schedule';
import QueryKey from 'src/api/QueryKey';
import { TaskSchedulingServiceApi } from 'src/api/TaskSchedulingServiceApi';
import { convertTime, getTimezoneOffset } from 'src/common/utils/date';
import { WorkdaysList } from 'src/common/constants/workday';
import {
  getAllocationTaskInput,
  getCreateTaskRequest,
  getUpdateTaskRequest,
} from 'src/common/utils/task';
import { useNotificationContext } from 'src/common/provider/NotificationProvider';
import { Notification } from 'src/common/constants/Notification';
import { useMetrics } from 'src/common/provider/MetricsProvider';
import { Page } from 'src/common/types/Page';
import { PageAction } from 'src/common/types/PageAction';
import { hasErrorInConfig } from 'src/common/hooks/useCreateDisabled';
import { useNavigationBlocker } from 'src/common/hooks/useNavigationBlocker';

const LeaveAlertModal = lazy(
  () => import('src/common/components/LeaveAlertModal'),
);

const AllocationConfigurationPage = () => {
  const { t } = useTranslation();
  const { taskId } = useParams();
  const navigate = useNavigate();
  const metrics = useMetrics();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { addNotification } = useNotificationContext();

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [schedule, setSchedule] = useState<Schedule>(defaultSchedule);
  const [driverMappingId, setDriverMappingId] = useState<string>();
  const [config, dispatch] = useReducer(reducer, defaultConfig);
  const [isLoadingNextStep, setIsLoadingNextStep] = useState(false);
  const {
    confirmationModal,
    onNavigationConfirm,
    onNavigationCancel,
    setIsBlocking,
  } = useNavigationBlocker();

  const action: Action = location.pathname.includes('update')
    ? Action.UPDATE
    : Action.CREATE;

  const task = useQuery({
    queryKey: [QueryKey.GetTask, taskId],
    queryFn: () => TaskSchedulingServiceApi.getTask({ taskId }),
    enabled: Boolean(taskId),
  });

  const createTask = useMutation({
    mutationFn: TaskSchedulingServiceApi.createTask,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  const updateTask = useMutation({
    mutationFn: TaskSchedulingServiceApi.updateTask,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  useEffect(() => {
    if (config.modelingInput) {
      if (config.modelingInput.driverMappingId) {
        setDriverMappingId(config.modelingInput.driverMappingId);
      }
    }
  }, [config.business?.value, config.modelingInput, config.region?.value]);

  useEffect(() => {
    if (!task.data) {
      return;
    }

    const allocation = task.data?.task;
    const taskInput =
      allocation?.taskType === TaskType.FAE_ALLOCATIONS_TASK
        ? allocation?.input?.allocationTaskInput
        : allocation?.input?.modeledAllocationTaskInput;
    dispatch({
      type: 'UPDATE',
      payload: {
        name: allocation?.name,
        description: allocation?.description,
        methodology: {
          value: taskInput?.methodology,
          label: taskInput?.methodology,
        },
        business: {
          value: taskInput?.business,
          label: taskInput?.business,
        },
        region: {
          value: taskInput?.region,
          label: taskInput?.region,
        },
        country: taskInput?.country?.map((value) => ({
          value,
          label: value,
        })),
        scenario: {
          value: taskInput?.scenario,
          label: taskInput?.scenario,
        },
        modelingInput:
          allocation?.taskType === TaskType.FAE_MODELED_ALLOCATIONS_TASK
            ? allocation.input?.modeledAllocationTaskInput?.modelingInput
            : undefined,
      },
    });

    const time =
      allocation?.schedule?.scheduleTime &&
      convertTime(allocation.schedule.scheduleTime, -getTimezoneOffset());

    setSchedule({
      enable: Boolean(allocation?.schedule),
      time: time ? { label: time, value: time } : null,
      workdayEnd:
        WorkdaysList.find(
          (workday) =>
            workday.value === allocation?.schedule?.workdayEnd?.toString(),
        ) ?? null,
      workdayStart:
        WorkdaysList.find(
          (workday) =>
            workday.value === allocation?.schedule?.workdayStart?.toString(),
        ) ?? null,
    });

    setActiveStepIndex(2);
  }, [task.data]);

  const handleNavigate = ({ detail }: NavigateDetailEvent) => {
    if (
      detail.requestedStepIndex === 1 &&
      detail.reason === 'next' &&
      hasErrorInConfig(config)
    ) {
      dispatch({
        type: 'UPDATE',
        payload: {
          error: {
            allocationConfigurationStep: t('allocation_configuration_error'),
          },
        },
      });
      return;
    }
    if (
      detail.requestedStepIndex === 2 &&
      detail.reason === 'next' &&
      config.business?.value === Business.AB
    ) {
      if (!driverMappingId) {
        dispatch({
          type: 'UPDATE',
          payload: {
            error: {
              driverMappingStep: t('driver_mapping_id_required'),
            },
          },
        });
        return;
      }
      setIsLoadingNextStep(true);
      dispatch({
        type: 'UPDATE',
        payload: {
          modelingInput: {
            driverMappingId,
          },
        },
      });
      setIsLoadingNextStep(false);
    }
    setActiveStepIndex(detail.requestedStepIndex);
  };

  const handleScheduleChange = useCallback((schedule: Schedule) => {
    setSchedule({ ...schedule });
  }, []);

  const handleSubmit = async () => {
    const startTime = performance.now();
    try {
      if (action === Action.UPDATE) {
        await updateTask.mutateAsync(
          getUpdateTaskRequest(
            taskId,
            config.description,
            getAllocationTaskInput(config),
            schedule,
          ),
        );
      } else {
        await createTask.mutateAsync(
          getCreateTaskRequest(
            config,
            schedule,
            getAllocationTaskInput(config),
          ),
        );
      }
      addNotification({
        type: 'success',
        content:
          action === Action.UPDATE
            ? t(Notification.allocation.update.success)
            : t(Notification.allocation.create.success),
      });
      metrics.publishCounter(
        `${Page.Allocation}.${action}`,
        PageAction.Success,
        1,
      );
      setIsBlocking(false);
      startTransition(() => navigate('/allocation/manage'));
    } catch (error: any) {
      addNotification({
        type: 'error',
        header:
          action === Action.UPDATE
            ? t(Notification.allocation.update.error)
            : t(Notification.allocation.create.error),
        content: error?.message,
      });
      metrics.publishCounter(
        `${Page.Allocation}.${action}`,
        PageAction.Failure,
        1,
      );
    }
    const endTime = performance.now();
    metrics.publishCounter(Page.Allocation, action, 1);
    metrics.publishTime(`${Page.Allocation}.${action}`, endTime - startTime);
  };

  const handleConfigChange = useCallback(
    (action: { type: string; payload?: Partial<Config> }) => {
      dispatch({
        type: action.type,
        payload: { ...action.payload, error: {} },
      });
    },
    [],
  );

  const handleStepChange = useCallback(
    (index: number) => setActiveStepIndex(index),
    [],
  );

  const steps = useSteps({
    config,
    onConfigChange: handleConfigChange,
    driverMappingId,
    onDriverMappingIdChange: (value: string) => {
      setDriverMappingId(value);
      // Clear any previous errors
      dispatch({
        type: 'UPDATE',
        payload: {
          error: {
            driverMappingStep: undefined,
          },
        },
      });
    },
    schedule,
    onScheduleChange: handleScheduleChange,
    onStepChange: handleStepChange,
    action,
  });

  return (
    <div data-testid="allocation-create-wizard">
      {confirmationModal && (
        <LeaveAlertModal
          disabled={createTask.isPending || updateTask.isPending}
          visible={confirmationModal}
          onCancel={onNavigationCancel}
          onConfirm={onNavigationConfirm}
        />
      )}
      <Wizard
        i18nStrings={i18nStrings(t)}
        onNavigate={handleNavigate}
        activeStepIndex={activeStepIndex}
        allowSkipTo
        isLoadingNextStep={
          createTask.isPending || updateTask.isPending || isLoadingNextStep
        }
        steps={steps}
        submitButtonText={action === Action.CREATE ? t('create') : t('update')}
        onSubmit={handleSubmit}
        onCancel={() => {
          startTransition(() => navigate('/'));
        }}
      />
    </div>
  );
};

export default AllocationConfigurationPage;
