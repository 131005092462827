import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Action } from '@amzn/fae-auth-service';
import { WizardProps } from '@cloudscape-design/components';
import { AllocationMethodologyStep } from '../steps/AllocationMethodologyStep';
import { ReviewStep } from '../steps/ReviewStep';
import { DriverMappingStep } from '../steps/DriverMappingStep';
import { Business } from '../config';
import { Schedule } from 'src/common/types';
import { Config } from 'src/common/types/Config';
import { CreateSchedule } from 'src/common/components/CreateSchedule';
import { getName } from 'src/common/utils/filters';

interface StepProps {
  action: Action;
  config: Config;
  onConfigChange(action: { type: string; payload?: Partial<Config> }): void;
  onScheduleChange(schedule: Schedule): void;
  onStepChange(step: number): void;
  schedule: Schedule;
  driverMappingId?: string;
  onDriverMappingIdChange(value: string): void;
}

export const useSteps = (props: StepProps) => {
  const { t } = useTranslation();
  const [steps, setSteps] = useState<WizardProps.Step[]>([]);

  useEffect(() => {
    const allocationConfigurationStep: WizardProps.Step = {
      title: t('select_allocation_methodology'),
      errorText: props.config.error?.allocationConfigurationStep,
      content: (
        <AllocationMethodologyStep
          config={props.config}
          onConfigChange={props.onConfigChange}
        />
      ),
    };

    const scheduleStep: WizardProps.Step = {
      title: t('schedule'),
      errorText: props.config.error?.scheduleStep,
      content: (
        <CreateSchedule
          columns={1}
          schedule={props.schedule}
          onScheduleChange={props.onScheduleChange}
        />
      ),
      isOptional: true,
    };

    const driverMappingStep: WizardProps.Step = {
      title: t('configure_allocation'),
      errorText: props.config.error?.driverMappingStep,
      content: (
        <DriverMappingStep
          config={props.config}
          onConfigChange={props.onConfigChange}
          driverMappingId={props.driverMappingId}
          onDriverMappingIdChange={props.onDriverMappingIdChange}
        />
      ),
    };

    const reviewStep: WizardProps.Step = {
      title:
        props.action === Action.CREATE
          ? t('review_and_create')
          : t('review_and_update'),
      errorText: props.config.error?.reviewStep,
      content: (
        <ReviewStep
          config={{ ...props.config, name: getName(props.config) }}
          schedule={props.schedule}
          onEdit={props.onStepChange}
          driverMappingId={props.driverMappingId!}
        />
      ),
    };

    if (props.config.business?.value === Business.AB) {
      setSteps([allocationConfigurationStep, driverMappingStep, reviewStep]);
    } else {
      setSteps([allocationConfigurationStep, scheduleStep, reviewStep]);
    }
  }, [
    props.action,
    props.config,
    props.onConfigChange,
    props.onScheduleChange,
    props.onStepChange,
    props.schedule,
    props.driverMappingId,
    t,
  ]);
  return steps;
};
