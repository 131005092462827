import React, { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import QueryKey from 'src/api/QueryKey';
import { AllocationServiceApi } from 'src/api/AllocationServiceApi';
import { ServerSideTable } from 'src/common/components/server-side-table/ServerSideTable';
import {
  downloadDriverMappingHandler,
  getNextPageDriverMappingsHandler,
  updateDriverMappingsHandler,
} from 'src/common/components/driver-mapping-components/utils/driverMapping';
import { sliceWorkflowColumnDefinitions } from 'src/common/components/driver-mapping-components/config/driver-mapping-table';

interface DriverMappingTableProps {
  filteringProperties: any;
  header: string;
  testId: string;
  sliceId: string | null;
  scenario: string | undefined;
}

const DriverMappingForSlice = ({
  filteringProperties,
  header,
  sliceId,
  scenario,
}: DriverMappingTableProps) => {
  const { data: driverMappingId } = useQuery({
    queryKey: [QueryKey.GetSliceInformation, sliceId],
    queryFn: () =>
      AllocationServiceApi.getSliceInformation({ sliceId: sliceId! }),
    select: (data) => data?.dmId,
    enabled: sliceId !== null,
  });
  const { t } = useTranslation();
  const colDefs = React.useMemo(() => sliceWorkflowColumnDefinitions(t), [t]);

  const downloadDriverMapping = useCallback(
    () =>
      downloadDriverMappingHandler(
        driverMappingId!,
        `${sliceId}-${driverMappingId}.json`,
      ),
    [driverMappingId],
  );
  const getNextPageDriverMappingEntries = useCallback(
    getNextPageDriverMappingsHandler(driverMappingId!),
    [driverMappingId],
  );
  const updateDriverMappingEntries = useCallback(
    updateDriverMappingsHandler(driverMappingId!),
    [driverMappingId],
  );

  return (
    <>
      {driverMappingId && (
        <ServerSideTable
          key={driverMappingId}
          driverMappingId={driverMappingId}
          columnDefinitions={colDefs}
          header={header}
          filteringProperties={filteringProperties}
          testId="driver-mapping-table"
          handleDownloadCallback={downloadDriverMapping}
          fetchNextPage={getNextPageDriverMappingEntries}
          updateDriverMappingEntries={updateDriverMappingEntries}
          scenario={scenario}
        />
      )}
    </>
  );
};

export default DriverMappingForSlice;
