export enum Environment {
  PRODUCTION = 'Prod',
  SIMULATION = 'Simulation',
}

export enum Scenario {
  ACTUALS = 'Actuals',
  PLANNING = 'Planning',
}

/**
 * This should go away as soon as we implement fetching the data source config from the last execution.
 */
export const SHADOW_PNL_ACTUALS_DEFAULT_DATA_SOURCE_CONFIG: Record<
  string,
  string
> = {
  snapshot_name: 'live',
  bazaar_guid: 'Actuals',
  version: 'v1',
  mapping_category: 'Actuals',
  target_scenario: 'Actuals',
};

export const SHADOW_PNL_PLANNING_DEFAULT_DATA_SOURCE_CONFIG: Record<
  string,
  string
> = {
  snapshot_name: 'OP1 2025 - Hotel',
  bazaar_guid: 'bguid_snap_220e5e60-667c-49f7-9c54-ac1e63e1d91f',
  version: 'v1',
  mapping_category: 'Planning',
  target_scenario: 'R&O - Current',
};

export const scenarioOptions = [
  { label: Scenario.ACTUALS.toString(), value: Scenario.ACTUALS.toString() },
  { label: Scenario.PLANNING.toString(), value: Scenario.PLANNING.toString() },
];

// TODO: Remove this after FAS API is ready
export enum Slices {
  SLICE1 = 'Slice1',
  SLICE2 = 'Slice2',
  SLICE3 = 'Slice3',
  SLICE4 = 'Slice4',
  SLICE5 = 'Slice5',
}

export const slicesOptions = [
  { label: Slices.SLICE1, value: '0' },
  { label: Slices.SLICE2, value: '1' },
  { label: Slices.SLICE3, value: '2' },
  { label: Slices.SLICE4, value: '3' },
  { label: Slices.SLICE5, value: '4' },
];

export const BAZAAR_GUID = 'bazaar_guid';
export const SNAPSHOT_NAME = 'snapshot_name';
