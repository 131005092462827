import { StatusIndicator, TableProps } from '@cloudscape-design/components';
import type { DriverMappingRecord } from '@amzn/allocations-service';
import React from 'react';
import { TFunc } from 'src/common/types';
import { DriverMapping } from 'src/common/types/DriverMapping';
import { DriverMappingAction } from 'src/common/components/driver-mapping-components/utils/driverMapping';

/**
 * Retrieves allocation functions from a driver mapping item.
 * @param item - The driver mapping item to extract allocation functions from
 * @returns The allocation functions as a string or array of strings
 */
export function getAllocationFunctions(item: DriverMapping) {
  return item.allocationFunctions
    ? item.allocationFunctions
    : item.allocationFunction;
}

/**
 * Extracts driver information from a driver mapping item.
 * @param item - The driver mapping item to extract drivers from
 * @returns Array of driver strings, filtered to remove empty values
 */
export function getDrivers(item: DriverMapping) {
  if (item.drivers) {
    return item.drivers;
  }

  return [item.primaryDriver, item.secondaryDriver, item.fallbackDriver]
    .map((item) => item)
    .filter((item) => Boolean(item)) as string[];
}

/**
 * Defines column configuration for the driver-mapping table.
 * @param t - Translation function for internationalization
 * @returns Array of column definitions for the table
 */
export const sliceWorkflowColumnDefinitions = (
  t: TFunc,
): TableProps.ColumnDefinition<DriverMappingRecord>[] => [
  {
    id: 'account',
    header: t('account'),
    cell: (item: DriverMappingRecord) => item.account,
    sortingField: 'account',
    minWidth: 120,
  },
  {
    id: 'costCenter',
    header: t('cost_center'),
    cell: (item: DriverMappingRecord) => item.costCenter,
    sortingField: 'costCenter',
    minWidth: 150,
  },
  {
    id: 'companyCode',
    header: t('company_code'),
    cell: (item: DriverMappingRecord) => item.companyCode,
    sortingField: 'companyCode',
    minWidth: 150,
  },
  {
    id: 'locationCode',
    header: t('location_code'),
    cell: (item: DriverMappingRecord) => item.locationCode,
    sortingField: 'locationCode',
    minWidth: 150,
  },
  {
    id: 'productCode',
    header: t('product_code'),
    cell: (item: DriverMappingRecord) => item.productCode,
    sortingField: 'productCode',
    minWidth: 150,
  },
  {
    id: 'projectCode',
    header: t('project_code'),
    cell: (item: DriverMappingRecord) => item.projectCode,
    sortingField: 'projectCode',
    minWidth: 150,
  },
  {
    id: 'channelType',
    header: t('channel_type'),
    cell: (item: DriverMappingRecord) => item.channelType,
    sortingField: 'channelType',
    minWidth: 150,
  },
  {
    id: 'allocationFunctions',
    header: t('allocation_function'),
    cell: (item: DriverMappingRecord) => getAllocationFunctions(item),
    sortingField: 'allocationFunctions',
    minWidth: 150,
  },
  {
    id: 'drivers',
    header: t('drivers'),
    cell: (item: DriverMappingRecord) => getDrivers(item).join(', '),
    sortingField: 'drivers',
    minWidth: 180,
  },
];

/**
 * Type definition for items in the update table, extending DriverMappingRecord with an action
 */
export type UpdatedDriverMappingItem = DriverMappingRecord & {
  action: DriverMappingAction;
};

/**
 * Creates column definitions for the pending changes table, including an action column.
 * @param t - Translation function for internationalization
 * @param tableColumnDef - Base column definitions to extend
 * @returns Column definitions with an action column prepended
 */
export const pendingChangesColumnDefinitions = (
  t: TFunc,
  tableColumnDef: TableProps.ColumnDefinition<DriverMappingRecord>[],
): TableProps.ColumnDefinition<UpdatedDriverMappingItem>[] => [
  {
    id: 'action',
    header: 'Action',
    cell: (item) => {
      const actionType = item.action;
      let type: 'success' | 'warning' | 'error';
      switch (actionType) {
        case DriverMappingAction.ADD:
          type = 'success';
          break;
        case DriverMappingAction.EDIT:
          type = 'warning';
          break;
        case DriverMappingAction.DELETE:
          type = 'error';
          break;
      }
      return <StatusIndicator type={type}>{t(actionType)}</StatusIndicator>;
    },
    width: 120,
  },
  ...tableColumnDef,
];
