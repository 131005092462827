import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@amzn/awsui-components-react/polaris/table';
import { TableProps as TableComponentProps } from '@cloudscape-design/components/table';
import {
  Box,
  ExpandableSection,
  SpaceBetween,
} from '@cloudscape-design/components';
import type { DriverMappingRecord } from '@amzn/allocations-service';
import {
  pendingChangesColumnDefinitions,
  UpdatedDriverMappingItem,
} from 'src/common/components/driver-mapping-components/config/driver-mapping-table';

/**
 * Props for the PendingChangesTable component
 */
interface PendingChangesTableProps {
  /** Array of pending changes to display */
  changes: UpdatedDriverMappingItem[];
  /** Column definitions for the table */
  columnDefinitions: TableComponentProps.ColumnDefinition<DriverMappingRecord>[];
  /** Whether the table is in a loading state */
  loading?: boolean;
  /** Test ID for the component */
  'data-testid'?: string;
}

/**
 * Displays pending changes in an expandable table using Cloudscape components.
 * Supports i18n, loading states, and empty state handling.
 */
export const PendingChangesTable: React.FC<PendingChangesTableProps> = ({
  changes,
  columnDefinitions,
  loading,
  'data-testid': testId = 'pending-changes-section',
}) => {
  const { t } = useTranslation();

  if (changes.length === 0) {
    return null;
  }

  return (
    <ExpandableSection
      headerText={t('pending_changes_header')}
      variant="container"
      data-testid={testId}
    >
      <SpaceBetween direction="vertical" size="s">
        <Table
          data-testid="pending-changes-table"
          items={changes}
          columnDefinitions={pendingChangesColumnDefinitions(
            t,
            columnDefinitions,
          )}
          variant="embedded"
          empty={<Box textAlign="center">{t('no_pending_changes')}</Box>}
          loading={loading}
          loadingText={t('table_loading')}
          contentDensity="compact"
          resizableColumns={true}
          stickyHeader={true}
        />
      </SpaceBetween>
    </ExpandableSection>
  );
};
